.orderDetail {
  padding-bottom: 160px;
}

.issueHeader {
  padding: 10px 30px;
  border-top: 1px dashed var(--gray100);
  color: var(--orange400);
}

.issueFooter {
  padding: 10px 30px;
  color: var(--gray600);
}
.tagProductListContainer {
  min-height: calc(100% - 54px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.loadingBox {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vw;
}
.searchForm {
  flex: 1;
}

.searchLabel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;
  background-color: var(--gray100);
  border-radius: 8px;
  margin: 4px 12px 4px 0;

  input {
    outline: none;
    border: none;
    background-color: inherit;
    flex: 1;
    font-size: 16px;
    color: var(--gray900);
    padding: 0;
  }
}

.searchIconBox {
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
}

.clearInputButton {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 20px;
    height: 20px;
  }
}
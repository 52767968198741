.labelWithButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  span {
    font-size: 12px;
  }
}

.flexRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.inform {
  font-size: 14px;
  color: var(--orange600)
}
.noResult {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 16px;
  gap: 10px;
  height: calc(100vh - 200px);
}

.requestInfo {
  font-weight: 500;
}

.requested {
  margin: 20px 0 6px;
  font-weight: 500;
}
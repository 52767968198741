// @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;400;700;900&display=swap&text=1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ');

html,
body {
  height: 100%;
}

body {
  font-family: Pretendard;
  // font-family: Outfit, Pretendard;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (min-width: 640px) {
    background-color: var(--gray300);
  }
}

#root {
  height: 100%;

  @media (min-width: 640px) {
    background-color: white;
    max-width: var(--desktop-max-width);
    margin: 0 auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-tab {
  padding: 10px 0;
  color: var(--gray700);
  font-size: 17px;
  line-height: 24px;
}

.ant-tabs-tab:first-of-type {
  margin-left: 32px;
}

.ant-tabs-tab:nth-last-child(2) {
  margin-right: 32px;
}
.homeSectionDivider {
  .line {
    width: 100%;
    height: 8px;
    background-color: var(--gray100);
  }

  h2 {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--gray800);
    padding: 20px 16px 10px;
    margin: 0;

    b {
      color: var(--gray900);
    }
  }
}
.userInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 20px;
  border-bottom: 1px solid var(--gray200);
}

.storeInfo {
  border-bottom: 1px solid var(--gray200);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 20px;

  :global .guest {
    p {
      color: var(--gray700);
    }
  }

  :global .open {
    div {
      color: var(--blue400);
    }
  }
}

.addressLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  button {
    span {
      font-size: 12px;
    }
  }
}

.storeEmailInfo {
  margin-top: 2px;
  font-size: 13px;
  font-weight: 500;
  color: var(--gray700);
  background-color: var(--gray200);
  padding: 4px 8px;
}

.notificationInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 20px;
  border-bottom: 1px solid var(--gray200);
}

.notificationTitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  line-height: 1.5;

  h2 {
    font-size: 18px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
    color: var(--gray700);
    padding-bottom: 12px;
  }
}

.flexRow {
  display: flex;
  align-items: center;
  gap: 8px;
}

.inform {
  font-size: 13px;
  color: var(--orange600)
}

.deleteAccount {
  padding: 30px 20px 100px;
}

.linkItem {
  color: var(--gray700);
}
$searchToolbarHeight: 54px;
$searchOptionHeight: 42px;

.marginTop {
  height: $searchToolbarHeight;

  &.showOption {
    height: $searchToolbarHeight + $searchOptionHeight;
  }
}

.searchOption {
  position: fixed;
  top: $searchToolbarHeight;
  z-index: var(--toolbar);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: $searchOptionHeight;
  background-color: white;
  font-size: 14px;
  padding: 0 16px;
}
.skeletonHorizontalItem {
  display: flex;
  border-bottom: 1px solid var(--gray200);
  padding: 16px 12px;
  gap: 8px;

  .squareImg {
    :global .ant-skeleton-image {
      width: 92px;
      height: auto;
      aspect-ratio: 1/1;
    }
  }
}
.historyItemContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid var(--gray100);
}

.orderInfo {
  flex: 1;
  padding-right: 10px;
  color: var(--gray900);
}

.orderDate {
  font-size: 13px;
  padding-bottom: 4px;
}

.orderAmount {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 10px;
}

.orderStateBox {
  display: flex;
  justify-content: space-between;
}

.submitted {
  color: #357ff7;
}

.accepted {
  color: #0c8585;
}

.delivered {
  color: var(--gray900);
}

.rejected {
  color: var(--red600);
}

.issues {
  color: var(--orange400);
}
.orderHistoryContainer {
  height: 100%;
  padding-bottom: var(--page-bottom-padding);
  background-color: white;
}

.groupList {
  height: 100%;
  padding-bottom: 20px;
}

.inform {
  color: var(--gray700);
  text-align: center;
  padding: 14px 0 6px;
  background-color: var(--gray100);
}

.summary {
  padding: 24px 20px;
  border-bottom: 1px dashed var(--gray300);
  color: var(--gray900);

  .topRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h3 {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;

    span {
      font-weight: 400;
    }
  }

  .info {
    display: flex;
    align-items: center;

    h2 {
      font-size: 24px;
      letter-spacing: -0.025em;
      font-weight: 800;

    }

    p {
      font-size: 20px;
      font-weight: 600;
    }
  }
}

.border {
  height: 8px;
  background-color: var(--gray100);
}
.latestSearchKeywords {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 16px;
}

.top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  padding: 0 16px;

  h3 {
    font-size: 16px;
    font-weight: 700;
    color: var(--gray900);
  }
}

.roundButton {
  display: inline-block;
  padding: 0 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  color: var(--gray900);
  background-color: var(--gray100);
}

.bottom {
  width: 100%;
  padding: 0 16px;
  overflow: auto;
  white-space: nowrap;

  /* IE and Edge */
  -ms-overflow-style: none;
  /* Firefox */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  .roundButton {
    font-size: 13px;
    font-weight: 500;
    color: var(--orange600);
    background-color: var(--orange100);
    margin-right: 6px;
  }
}

.deleteKeyword {
  display: inline-block;
  font-weight: 700;
  margin-left: 4px;
}
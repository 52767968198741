.billImageContainer {
  background-color: white;

  h2 {
    font-size: 14px;
    text-align: left;
    padding: 0 14px 8px;
  }
}

.topButton {
  position: absolute;
  top: 12px;
  right: 16px;
  z-index: 1;
}

.top {
  padding: 12px 16px;
  line-height: 32px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--gray100);

  &.warn {
    background-color: var(--red100);
    color: var(--red500);
  }
}

.summary {
  margin: 14px 16px;
  border: 1px solid var(--gray300);
  border-radius: 4px;
  color: var(--gray900);
}

.item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 16px;
  border-bottom: 1px solid var(--gray300);

  &:last-child {
    border-bottom: none;
  }

  &>div:last-child {
    text-align: right;
    flex: 1;
  }

  .label {
    font-weight: 400;
  }

  .settlementAmount {
    font-size: 28px;
    font-weight: 700;

    span {
      padding-left: 6px;
      font-size: 16px;
    }
  }
}

.details {
  margin: 0 16px 16px;
  border: 1px solid var(--gray300);
  border-radius: 4px;
  color: var(--gray900);
}

.detailItem {
  display: grid;
  grid-template-columns: 2.8fr 3.2fr 2.8fr 3.2fr;
  padding: 6px 10px;
  border-bottom: 1px solid var(--gray300);

  &.header {
    background-color: var(--gray100);
  }

  &.attention {
    color: var(--red500);
    font-weight: 700;
  }

  &.sum {
    border-bottom: 1px solid var(--gray400);
  }

  &.total {
    border-top: 1px solid var(--gray400);
    font-weight: 700;
    background-color: var(--yellow200);
  }

  &:last-child {
    border-bottom: none;
  }

  &>div {
    text-align: right;
    padding-right: 8px;

    &:first-child {
      padding-left: 8px;
      padding-right: 0;
      text-align: left;
    }
  }
}

.paid,
.attention,
.total {
  grid-template-columns: 6fr 6fr;
}

.total {
  font-weight: 600;
}

.extraItem {
  display: flex;
  padding: 6px 10px;

  .price {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 8px;
    font-weight: bold;
  }

  &>div {
    padding-left: 8px;
    flex: 1;
  }
}

.message {
  border-top: 1px dashed var(--gray300);
  padding: 12px 20px;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-line;
  word-break: keep-all;
}
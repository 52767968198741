.flexRow {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    color: var(--gray800);
    font-weight: 600;
  }
}

.more {
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay {
  position: relative;
  z-index: var(--modal);
}

.result {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  background-color: var(--backdrop60);
  box-sizing: border-box;
}

.previewList {
  padding-top: 54px;
  background-color: white;
}

.empty {
  height: calc(100vh - 54px);
}

.loading {
  padding-top: 108px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
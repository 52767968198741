.relativeBox {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 20px;
  gap: 20px;

  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.fixedBox {
  position: fixed;
  display: flex;
  padding: 1px 16px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  top: 0;
  z-index: var(--toolbar);
  opacity: 0;
  transition: opacity 250ms ease-out;
  background-color: white;
  gap: 8px;
  z-index: -1;

  &.show {
    opacity: 1;
    z-index: var(--toolbar);
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--gray300);
  }
}

.full {
  flex: 1;
}
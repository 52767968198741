.topNavigationBarContainer {
  position: sticky;
  background-color: white;
  z-index: 15;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding: 3px 4px;

  &.borderBottom {
    border-bottom: 1px solid var(--gray200);
  }

  .emptyBox {
    color: var(--gray700);
    font-size: 20px;
    line-height: 20px;
    padding: 14px;
    min-width: 48px;
  }

  h3 {
    color: var(--gray800);
    text-align: center;
    flex: 1;
    margin: 0;
  }

  button:disabled {
    color: var(--gray400);
  }

  button {
    letter-spacing: -0.025em;
    font-size: 14px;
  }
}
.horizontalProductList {
  display: flex;
  gap: 2px;
  overflow: auto hidden;
  padding-right: 32px;
  scroll-snap-type: x mandatory;

  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.horizontalProductList::-webkit-scrollbar {
  display: none;
}
.toolBar {
  position: sticky;
  top: 0;
  z-index: var(--toolbar);
  background-color: rgba(255, 255, 255, 0.92);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--gray700);

  .spacer {
    width: 48px;
  }
}

.left {
  text-align: left;
  flex: 1;
}

.searchToolBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: var(--toolbar);
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
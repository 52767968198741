.text {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray900);
  word-break: keep-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
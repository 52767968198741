.signinContainer {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.signinContent {
  padding: 0 28px 20px 28px;
  flex: 1;
  background-color: white;
}

.submitSigninButton {
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  height: 52px;
  margin-top: 20px;
}

.loadingBox {
  position: fixed;
  font-size: 42px;
  color: var(--orange500);
  top: calc(50% - 60px);
  left: calc(50% - 21px);
}

.buttons {
  display: flex;
  justify-content: space-between;
}

.cs {
  padding: 20px 28px;
  width: 100%;
  background-color: var(--gray75);
  border-top: 1px solid var(--gray100);

  p {
    color: var(--gray700);
    line-height: 1.5;
    margin: 10px 0;
    font-size: 13px;
  }
}

.csTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 700;
}

.kakaoLink {
  background-color: var(--gray75);
  padding-right: 20px;
  font-weight: 600;

  img {
    width: 20px;
    height: 22px;
    vertical-align: top;
  }
}
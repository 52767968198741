.more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.noticeBar {
  padding: 6px 16px;
  background-color: var(--yellow300);
  color: var(--brown600);
  font-weight: 600;
  font-size: 14px;
}
.skeletonSquareItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: var(--square-img-width);
  flex-shrink: 0;
  padding-left: 16px;
  margin-left: -8px;

  &:first-of-type {
    margin-left: 0;
  }

  .squareImg {
    :global .ant-skeleton-image {
      width: 100%;
      height: 100%;
      aspect-ratio: 1/1;
    }
  }
}
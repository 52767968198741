.middleIconBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;

  img {
    transition: transform 0.2s ease-out;
  }
}

.expanded {
  transform: rotate(90deg);
}
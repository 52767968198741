$animation: cubic-bezier(0.79, 0.33, 0.14, 0.53);

.wrapper {
  position: relative;
  max-width: 200px;
  width: 100%;
}

.volumeController {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  text-decoration: underline;
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid var(--orange200);
  align-items: center;
  overflow: hidden;
  transition: 250ms width $animation;
  background-color: rgba(255, 255, 255, 0.8);
}

.fixedToRight {
  position: absolute;
  z-index: 1;
  right: 0;
}

.animate {
  transition: 250ms transform $animation, 150ms opacity ease-out;
  transform: translateX(1em);
  opacity: 0;
}

.added {
  .animate {
    transition: 100ms 250ms transform $animation, 100ms 250ms opacity ease-in;
    transform: translateX(0);
    opacity: 1;
  }
}

.removeButton {
  border: 1px solid var(--red600);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 100% !important;
    height: 100%;
  }
}
.searchAddressTitle {
  margin-top: 28px;
  font-size: 24px;
  font-weight: 700;
}

.searchAddressButton {
  width: 100%;
  height: 48px;
  border-radius: 4px;
  border-width: 2px;
  border-color: var(--gray700);
  margin-top: 20px;
  font-size: 16px;
}

.searchStoreIdButton {
  display: block;
  margin-top: 14px;
  padding-left: 0;
  font-weight: 500;
  color: var(--gray700);
}

.skipAddressButton {
  display: block;
  margin-top: 6px;
  padding-left: 0;
  font-weight: 500;
  color: var(--gray700);
}
:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}


button {
  cursor: pointer;
  border: none;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  padding: 0;
  outline: none;
}

:root {
  --adm-center-popup-max-width: 90vw;
  --desktop-max-width: 540px;
  --orange50: #FFF8F5;
  --orange100: #FFEAE0;
  --orange200: #FFC8B3;
  --orange300: #FF9466;
  --orange400: #E68819;
  --orange500: #FF9C75;
  --orange600: #FF7F4C;
  --orange700: #FF5E1A;
  --orange800: #FF4800;
  --orange900: #E54100;

  --yellow100: #fffbea;
  --yellow300: #ffea9d;
  --yellow400: #ffd230;

  --red100: #fdf1f2;
  --red300: #fed7d6;
  --red400: #E34850;
  --red500: #D7373F;
  --red600: #C9252D;
  --red700: #BB121A;

  --blue400: #1890ff;
  --blue500: hsla(217, 90%, 50%, 0.7);

  --green500: hsla(161, 45%, 36%, 0.7);
  --brown500: hsla(30, 100%, 25%, 0.7);
  --brown600: hsla(30, 100%, 25%, 1);

  --gray75: #FAFAFA;
  --gray100: #F5F5F5;
  --gray200: #EAEAEA;
  --gray300: #E1E1E1;
  --gray400: #C4C7C5;
  --gray500: #B3B3B3;
  --gray600: #8E8E8E;
  --gray700: #6E6E6E;
  --gray800: #4B4B4B;
  --gray900: #2C2C2C;

  --white-backdrop70: rgba(255, 255, 255, 0.7);
  --backdrop60: rgba(84, 73, 69, 0.6);
  --backdrop: rgba(255, 255, 255, 0.4);
  --gray-alpha-40: rgba(0, 0, 0, 0.4);
  --gray-alpha-28: rgba(0, 0, 0, 0.28);
  --overlay-border: rgba(0, 0, 0, 0.1);

  --ogray700: #766A65;
  --ogray900: #4C3A32;

  // border가 있기 때문에 실제로는 1px을 더해야한다.
  --page-bottom-padding: 140px;

  --adm-color-primary: #E68819;

  --square-img-width: calc(50% - 10px);

  // z-index
  --dnd-preview: 100;
  --toolbar: 400;
  --modal: 500;

  --safe-footer-bottom: env(safe-area-inset-bottom);
  --safe-footer-bottom-with-nav: calc(59px + env(safe-area-inset-bottom));
  --safe-footer-card-bottom-with-nav: calc(69px + env(safe-area-inset-bottom));
}

button {
  cursor: pointer;
}

h1,
h2,
h3,
p,
span {
  margin: 0;
}

.loading {
  position: fixed;
  top: 0;
  height: 100vh;
  left: 0;
  width: 100vw;
  background-color: var(--backdrop);
}

.finish-line {
  padding-bottom: 72px;
}

.addPadding {
  padding-bottom: var(--page-bottom-padding);
}

.redColor {
  color: var(--red600);
}

.globalPadding {
  padding: 16px;
}

.modalContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: white;
  padding-bottom: 20px;
  z-index: var(--modal);
}

.no-scroll {
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  left: 0px;
}

.sub-text {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray700);
}

.titlePadding {
  padding: 10px 16px;
}

.promotionTitlePadding {
  padding: 20px 16px 16px;
}

.openKakao {
  color: var(--blue400);
  border-bottom: 1px solid var(--blue400);

  img {
    width: 18px;
    height: 19px;
    vertical-align: text-top;
  }
}
.previewItem {
  display: flex;
  font-size: 16px;
  color: var(--gray800);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 46px;

  img {
    margin: 0 4px 0 16px;
    width: 18px;
    height: 18px;
  }

  em {
    font-style: normal;
    color: var(--orange500)
  }
}
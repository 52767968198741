.addressBox {
  :global .ant-col:first-child {
    color: var(--gray600);
  }
}

.editSearchAddressButton {
  margin: 10px 0 20px;
}

.addressDetailInput {
  :global .ant-form-item-label label {
    height: 22px;
    color: var(--gray600);
  }
}

.storeNameInput {
  border-top: 1px dashed var(--gray300);
  padding-top: 20px;
  :global .ant-form-item-label label {
    height: 22px;
    font-weight: 700;
  }
}

.categoryInput {
  :global .ant-form-item-label label {
    height: 22px;
  }
}

.submitStoreButton {
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  height: 52px;
}

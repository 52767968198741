.handle {
  user-select: none;
  /* 텍스트 선택 방지 */
  -webkit-user-drag: none;
  /* Safari에서 드래그 방지 */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--gray200);
  width: 36px;

  img {
    width: 24px;
    height: 24px;
    user-select: none;
    /* 텍스트 선택 방지 */
    -webkit-user-drag: none;
    /* Safari에서 드래그 방지 */
  }
}
.orange {
  color: var(--orange700);
}

.gray {
  color: var(--gray800);
}

// VolumeButton
.volumeButton {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ExpandButton
.expandButton {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--gray900);

  img {
    width: 24px;
    height: 24px;
  }

  &.medium {
    font-size: 22px;
  }

  &.small {
    font-size: 16px;
    gap: 0;

    img {
      width: 18px;
      height: 18px;
    }
  }
}

// IconButton
.closeButton,
.backButton {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0);

  img {
    width: 24px;
    height: 24px;
  }
}

// GoToPageButton
.goToPageButton {
  display: flex;
  align-items: center;
  color: var(--gray800);
  font-size: 14px;
  font-weight: 500;

  img {
    width: 18px;
    height: 18px;
  }

  &.large {
    width: 100%;
    height: 54px;
    font-size: 18px;
    line-height: 30px;
    padding: 0 14px 0 16px;
    gap: 2px;

    img {
      width: 20px;
      height: 20px;
    }
  }

  .spacer {
    flex: 1;
  }
}

// TextButton
.textButton {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
}

// SearchButton
.searchButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 12px 16px;
  background-color: var(--gray100);
  // padding: 11px 16px;
  // border: 1px solid var(--orange700);
  gap: 12px;
  width: 100%;
  border-radius: 8px;

  &.iconOnly {
    background-color: inherit;
    justify-content: center;
    width: 48px;
    height: 48px;
    padding: 0;
    border: none;
    border-radius: 0;
  }

  span {
    color: var(--gray700);
    font-size: 16px;
    line-height: 24px;
  }
}

.rectButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 20px;
  height: 42px;
  border-radius: 6px;
  padding: 11px 10px 11px 20px;
  border: 1px solid var(--gray400);
  color: var(--gray800);
  font-size: 14px;
  font-weight: 500;
}
.orderInfo {
  padding: 20px 30px;

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
  }

  span {
    font-size: 14px;
    font-weight: normal;
    color: var(--gray700);
  }
}

.deliveryFee {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid var(--gray100);
  line-height: 1.5;
  font-weight: 500;

  .promotiveMsg {
    font-size: 14px;
    color: var(--orange700);
  }

  .content {
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      color: var(--gray600);
    }
  }
}
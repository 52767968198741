.badge {
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  padding: 0 3px;
  border: 1px solid;
  border-radius: 2px;
  display: flex;
}

.green {
  color: var(--green500);
  border-color: var(--green500);
}

.blue {
  color: var(--blue500);
  border-color: var(--blue500);
}

.orange {
  color: var(--orange700);
  border-color: var(--orange700);

  &.noBorder {
    border-color: var(--orange100);
    background-color: var(--orange100);
  }
}

.brown {
  color: var(--brown500);
  border-color: var(--brown500);
}
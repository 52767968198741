.storeIssueItem {
  padding: 10px 30px;
  border-bottom: 1px solid var(--gray100);
}

.message {
  font-size: 15px;
  font-weight: 600;
  color: var(--gray800);
}

.issuePrice {
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  span:first-child {
    color: var(--gray600);
  }

  span:last-child {
    font-weight: 500;
  }
}
.drawerContainer {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.loggedInUser {
  display: flex;
  flex-direction: column;
}

.infoBox {
  border-bottom: 1px solid var(--gray200);
  padding: 10px 0;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
}

.userInfo {
  word-break: break-word;

  .storeName {
    display: flex;
    align-items: center;
    font-weight: 600;
  }

  .email {
    font-size: 13px;
    line-height: 20px;
    color: var(--gray700);
  }

  .isGuest {
    font-size: 12px;
    line-height: 18px;
    padding: 1px 6px;
    border-radius: 2px;
    margin: 1px 0 0 4px;
    color: white;
    background-color: var(--orange400);
  }
}

.logout {
  color: var(--gray800);
  text-align: right;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.signinButtons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: var(--gray800);

  button {
    text-align: left;
    width: 100%;
    font-size: 16px;
    padding: 12px 16px 12px 0;
    border-bottom: 1px solid var(--gray100);

    span {
      margin-right: 6px;
    }
  }
}

.drawerFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--gray200);

  .closeButton {
    width: 48px;
    height: 48px;
    padding-left: 16px;
  }
}

.appInfo {
  font-size: 12px;
  line-height: 1.5;
  color: var(--gray700);
}

.linkList {
  margin-top: 20px;
  font-weight: 600;
}

.linkItem {
  color: var(--gray900);
  display: flex;
  align-items: center;
  padding: 12px 0 12px 6px;

  p {
    flex: 1;
  }
}

.extraSpace {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.section {
  width: 100%;

  p {
    color: var(--gray700);
    line-height: 1.5;
    margin: 10px 0;
    font-size: 13px;
  }
}

.sectionTop {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 700;
  min-height: 36px;
}

.extraIcon {
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.availableLocation {
  margin-bottom: 20px;

  .sectionTop {
    margin-bottom: 0;
  }

  p {
    margin: 0;
  }
}

.kakaoLink {
  border-color: var(--yellow400);
  padding-right: 20px;
  font-weight: 600;

  img {
    width: 20px;
    height: 22px;
    vertical-align: top;
  }
}

.locationModal {
  p {
    margin: 0;
    font-size: 15px;
    line-height: 1.5;
    color: var(--gray900);
    word-break: keep-all;

    span {
      font-size: 14px;
      color: var(--gray700);
    }
  }
}

.locationImageBox {
  width: calc(88vw - 24px);

  img {
    width: 100%;
    height: 100%;
  }
}
.productHorizontalItem {
  flex: 1;
  display: flex;
  border-bottom: 1px solid var(--gray200);
  padding: 16px 12px;
  gap: 8px;
}

.leftBox {
  width: 92px;
}

.rightBox {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 4px;
  // min-height: 106px;
}

.flexRow {
  display: flex;
  align-items: flex-start;
  padding-right: 2px;
}

.flexRowCenter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controllerBox {
  width: 116px;
}

.name {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.025em;
  color: var(--gray900);

  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.flexColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.price {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  color: var(--gray900);
}

.unitPrice {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: var(--gray600);
}

.badgeList {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}
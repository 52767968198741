.checkbox {
  position: relative;
  display: flex;
  padding-left: 46px;
  align-items: center;
  height: 54px;
  min-height: 48px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: var(--gray800);

  &.checked {
    font-weight: 700;
    color: var(--gray900);
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1.5px solid var(--gray400);
  }
}

.checkbox img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  width: 20px;
  height: 20px;
  z-index: 1;
}
.productContainer {
  position: relative;
  width: 100%;
  padding: 16px 12px 10px;
  display: flex;
  gap: 8px;
  border-bottom: 1px solid var(--gray200);

  &.added {
    background-color: var(--gray100);
  }

  &.showDetail {
    padding: 16px 12px;
  }
}

.productImageBox {
  position: relative;
  width: 92px;
  height: 96px;
  border-radius: 6px;
  overflow: hidden;
}

.icedIcon {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 24px;
  height: 24px;
}

.badge {
  position: absolute;
  top: 4px;
  left: 4px;
  padding: 2px 7px;
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  color: white;
  text-align: center;
  border-radius: 4px;
  background-color: var(--orange900);
  word-break: keep-all;
}

.imageDescription {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  color: white;
  background-color: var(--backdrop60);
  text-align: center;
  word-break: keep-all;
}

.productContentBox {
  flex: 1;
  gap: 4px;
  min-height: 106px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.showDetail {
  .productContentBox {
    gap: 8px;
  }
}

.contentTop {
  flex: 1;
  display: flex;
  align-items: flex-start;
}

.productTitle {
  flex: 1;

  .extraInfo {
    display: flex;
    gap: 4px;
    padding-top: 2px;

    span {
      display: inline-block;
      font-size: 10px;
      line-height: 14px;
      font-weight: 600;
      padding: 0 4px;
      border-radius: 2px;
      border: 1px solid;

      &.box {
        color: rgba($color: #7E4A4A, $alpha: 0.7);
        border-color: rgba($color: #7E4A4A, $alpha: 0.7);
      }

      &.refrigerator {
        color: rgba($color: #32856b, $alpha: 0.7);
        border-color: rgba($color: #43a384, $alpha: 0.7);
      }

      &.freezer {
        color: rgba($color: #0D64F0, $alpha: 0.7);
        border-color: rgba($color: #0D64F0, $alpha: 0.7);
      }
    }
  }

  .name {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.025em;
    color: var(--gray900);

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.favorite {
  font-size: 20px;
  text-align: center;
  padding: 8px;
  color: var(--gray400);

  &.filled {
    color: var(--orange500);
  }
}

.contentBottom {
  display: flex;
  gap: 4px;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}

.priceBox {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.productPrice {
  font-size: 17px;
  line-height: 24px;
  font-weight: 700;
  color: var(--gray900);
}

.unitPrice {
  font-size: 13px;
  line-height: 16px;
  font-weight: 500;
  color: var(--gray600);
}

.invalid {
  .priceBox {
    text-decoration: line-through;
  }
}

.invalidImg {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdrop60);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 700;
}
.loadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loadingIcon {
  font-size: 22px;
  color: var(--orange600),
}

.alreadyRequest {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 60px;
}

.requestForm {
  padding: 0 28px 28px;
}

.deleteRequestButton {
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  height: 40px;
}
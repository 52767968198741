.barContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  background-color: white;
  gap: 10px;
  border-bottom: 1px solid var(--gray200);
}

.info {
  font-size: 14px;
}

.controlBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 14px;
}

.datePickerButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // border: 1px solid var(--gray200);
  border-radius: 4px;
  flex: 2;
  max-width: 240px;
}

.tilde {
  padding: 0 2px;
}

.sendButton {
  height: 34px;
  max-width: 96px;
  padding: 4px 8px;
  box-shadow: none;
  flex: 1;
}
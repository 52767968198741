.editOrder,
.cancelOrder {
  font-weight: 700;
  padding: 0 12px;
}

.cancelOrder {
  font-weight: 400;
  color: var(--red400);
}

.deliveryInfo {
  padding: 10px 30px 20px;

  h2 {
    font-weight: bold;
    font-size: 24px;
    line-height: 1.5;
  }

  span {
    font-size: 14px;
    font-weight: normal;
    color: var(--gray700);
  }
}

.gutter {
  height: 8px;
  background-color: var(--gray75);
}
.footer {
  border-top: 1px solid #F5F5F5;
  position: fixed;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  display: flex;
  justify-content: space-between;
  background-color: white;
  z-index: 100;
  padding-bottom: var(--safe-footer-bottom);

  @media (min-width: 640px) {
    max-width: var(--desktop-max-width);
  }
}

.menuItem {
  padding-top: 7px;
  height: 48px;
  color: var(--gray400);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .menuItemTitle {
    margin-left: 0;
    font-size: 10px;
    font-weight: bold;
    line-height: 14px;
  }

  :global .anticon {
    font-size: 20px;
    padding: 2px;
    color: var(--gray400);
  }

  &.selected {
    color: var(--gray900);

    :global .anticon {
      color: var(--gray900);
    }
  }
}
.top {
  height: 54px;
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  color: var(--gray900);
  border-bottom: 1px solid var(--gray400);
}

.gridList {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 1px;
}

.recipientInfo {
  margin-bottom: 20px;
  padding: 16px;
  font-size: 16px;
  list-style: 22px;

  &>span {
    min-height: 32px;

    &:nth-child(odd) {
      color: var(--gray700)
    }

    &:nth-child(even) {
      text-align: right;
      color: var(--gray900)
    }
  }
}

.message {
  border-top: 1px dashed var(--gray300);
  font-size: 14px;
  line-height: 1.5;
  padding: 16px;
  white-space: pre-line;
  word-break: keep-all;
}

.invoiceItemList {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.invoiceItem {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  font-size: 15px;
  line-height: 20px;
  color: var(--gray600);

  &.storeIssue .name {
    color: var(--orange700);
  }

  &>div {
    padding: 2px 0;
  }

  .price {
    display: grid;
    grid-template-columns: 2fr 1fr;

    .total {
      text-align: right;
    }
  }
}

.sumTotalPrice {
  margin-top: 2px;
  padding: 5px 16px;
  font-size: 16px;
  line-height: 22px;

  &>span {
    &:nth-child(odd) {
      color: var(--gray700)
    }

    &:nth-child(even) {
      text-align: right;
      color: var(--gray900)
    }
  }
}

.sumSupply,
.sumTax {
  font-size: 14px;
  line-height: 18px;
  color: var(--gray600);
  padding: 5px 16px;

  &>span {
    &:nth-child(odd) {
      display: flex;
      gap: 8px;
      align-items: center;
    }

    &:nth-child(even) {
      text-align: right;
    }
  }
}

.sumTax {
  margin-bottom: 10px;
}

.invoiceAmount {
  border-top: 1px dashed var(--gray400);
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  padding: 20px 16px 5px;
  margin-top: 20px;

  &>span {
    &:nth-child(odd) {
      color: var(--gray700)
    }

    &:nth-child(even) {
      text-align: right;
      color: var(--gray900)
    }
  }
}
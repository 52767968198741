.footerButton {
  position: fixed;
  z-index: 100;
  font-size: 16px;
  font-weight: bold;
  bottom: var(--safe-footer-bottom-with-nav);
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 32px);
  max-width: calc(var(--desktop-max-width) - 32px);
  color: white;
  background-color: var(--orange600);
  border: none;
  border-radius: 8px;
  height: 54px;
  letter-spacing: 0.0125em;

  &:disabled {
    background-color: var(--gray400);
  }
}
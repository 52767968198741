.productThumbnail {
  position: relative;
  width: 100%;
  overflow: hidden;
  aspect-ratio: 1/1;
}

.overlayBox {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid var(--overlay-border);
  z-index: 2;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .bottom {
    padding: 6px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.imageDescription {
  padding: 4px 8px;
  background-color: var(--gray-alpha-28);
  color: white;
  font-size: 12px;
  font-weight: 700;
}

.invalid {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdrop60);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 16px;
  font-weight: 700;
}
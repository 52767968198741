.volumeControler {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid var(--orange200);
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: row-reverse;
  transition: width 0.2s ease-out;
  background-color: white;

  &.added {
    width: 116px;

    .volume {
      display: inline-block;
    }

    .minus {
      display: flex;
    }
  }

  .volume {
    display: none;
    flex: 1;
    text-align: center;
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    color: var(--gray900);
    text-decoration: underline;
  }

  button {
    border-radius: 50%;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 38px;
    box-shadow: none;

    img {
      width: 16px;
    }
  }

  .minus {
    display: none;
  }
}

// .container {
//   width: 40px;
//   height: 40px;
//   border: 1px solid var(--orange200);
//   display: flex;
//   overflow: hidden;
//   align-items: center;

//   &[data-added="true"] {
//     width: 116px;

//     .minus {
//       display: flex;
//     }

//     .volume {
//       display: block;
//     }
//   }

//   button {
//     width: 38px;
//     height: 38px;
//     display: flex;
//     justify-content: center;
//     align-items: center;

//     &.minus {
//       display: none;
//     }
//   }
// }

// .volume {
//   display: none;
//   flex: 1;
//   text-align: center;
//   font-size: 18px;
//   line-height: 20px;
//   font-weight: 500;
//   color: var(--gray900);
//   text-decoration: underline;
// }
.productSquareItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex-shrink: 0;

  &.horizontal {
    scroll-snap-align: start;
    width: var(--square-img-width);
    padding-left: 16px;
    margin-left: -8px;

    &:first-of-type {
      margin-left: 0;
    }
  }
}
.emptyList {
  display: flex;
  height: calc(100vh - 54px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h3 {
    margin-bottom: 10px;
    white-space: pre-line;
  }

  button {
    margin-top: 10px;
    padding: 6px 14px;
    font-weight: 600;
  }
}
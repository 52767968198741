.notiItem {
  display: flex;
  padding: 20px;

  &.unread {
    background-color: var(--gray100);
  }
}

.notiIcon {
  margin-top: 4px;
  margin-right: 12px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--orange400);
  }
}

.notiContent {
  flex: 1;

  p {
    font-size: 16px;
    letter-spacing: -0.025em;
    color: var(--gray900);
  }

  div {
    width: 100%;
    font-size: 13px;
    color: var(--gray600);
  }

  .notiInfo {
    font-weight: 600;
  }
}
.loadingBox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.icon {
  font-size: 32px;
  color: var(--orange500);
}
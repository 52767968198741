.price {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  color: var(--gray900);

  &.large {
    font-size: 17px;
    line-height: 24px;
  }
}

.unitPrice {
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--gray600);
}
.updateOrderButtons {
  position: fixed;
  z-index: 100;
  bottom: var(--safe-footer-bottom-with-nav);
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 32px);
  max-width: calc(var(--desktop-max-width) - 32px);
  display: flex;

  button {
    font-weight: bold;
    border: none;
    border-radius: 8px;
    height: 54px;
    font-size: 16px;

    &.cancel {
      width: 96px;
      color: var(--gray800);
      background: var(--gray300);
      margin-right: 8px;
    }

    &.updateOrder {
      position: relative;
      flex: 1;
      color: white;
      background-color: var(--gray900);

      &:disabled {
        background-color: var(--gray400);
      }
    }
  }
}

.promotiveMsg {
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  span {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    line-height: 19px;
    padding-top: 1px;
    letter-spacing: 0.0125em;
    color: var(--orange900);
    padding: 0px 8px;
    background-color: rgba($color: #FFF8F5, $alpha: 0.9);
    border-radius: 10px;
  }
}
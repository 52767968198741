.orderCard {
  position: fixed;
  overflow: hidden;
  bottom: 0;
  left: 50%;
  width: 100%;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 20px 20px 0 0;
  // 49는 footer nav버튼의 높이
  padding: 30px 30px;
  padding-bottom: var(--safe-footer-card-bottom-with-nav);
  background-color: white;
  z-index: 11;

  h3 {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    margin: 0;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin: 0;
    color: var(--gray700);
  }

  @media (min-width: 640px) {
    max-width: var(--desktop-max-width);
  }

  button {
    border: none;
    background-color: inherit;
  }
}

.drawerHandle {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 7px;
  cursor: pointer;

  span {
    font-size: 18px;
    color: var(--gray200);
    transition: transform 0.2s;
  }

  &.open {
    span {
      transform: rotate(180deg);
    }
  }
}

.mainBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  button {
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    /* GRAY 800 */
    color: var(--gray800);
    /* GRAY 100 */
    background: var(--gray100);
    border-radius: 4px;
    padding: 4px 13px;
  }
}

.extraBox {
  padding-bottom: 10px;
}

.buttonBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 26px auto 0;
  gap: 10px;

  button {
    flex: 1;
    border-radius: 4px;
    background: var(--gray100);
    color: var(--gray800);
    padding: 10px 13px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
  }

  .cancelOrder {
    color: var(--red400);
    background: var(--red100);
  }
}

.emptyBox {
  height: 68px
}
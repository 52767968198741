.dropdown {
  padding-right: 4px;

  :global .adm-dropdown-nav {
    border: none;
  }
}

.radioList {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;

  label {
    padding: 10px;
  }
}
.loadingContainer {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: var(--orange400);
  }
}
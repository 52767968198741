.accordion {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: white;
  padding: 20px 16px;
  width: 100%;

  .accordionTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    color: var(--gray900);
  }

  &.medium {
    padding: 4px 16px;

    .accordionTitle {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: var(--gray700);
    }
  }
}


.pannel {
  background-color: white;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.25s ease-out, opacity 0.15s ease-out 0.1s;

  &.expanded {
    opacity: 1;
  }

  &.two {
    background-color: var(--gray75);

    .levelLine {
      margin: 10px 16px;
      border-left: 1px solid var(--gray400);
    }
  }
}
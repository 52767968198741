.productInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  min-height: 102px;
}

.badgeList {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.rank {
  white-space: pre;
}
.imgBox {
  position: relative;
  width: 100%;
  height: 100vw;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .extraInfo {
    position: absolute;
    top: 16px;
    right: 16px;

    img {
      width: 42px;
      height: 42px;
      margin-left: 6px;
    }
  }

  .imageDescription {
    position: absolute;
    bottom: 0px;
    right: 0px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--gray700);
    background-color: var(--white-backdrop70);
    text-align: center;
    padding: 2px 10px;
  }
}

.productInfo {
  padding: 18px 20px 16px;
  border-bottom: 1px solid var(--gray200);
}

.badge {
  display: inline-block;
  padding: 2px 8px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: white;
  text-align: center;
  border-radius: 2px;
  background-color: var(--orange700);
}

.productName {
  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  margin: 4px 0 12px;
}

.productContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productPrice {
  display: flex;
  flex-direction: column;
}

.price {
  font-size: 26px;
  letter-spacing: -0.25px;
  font-weight: 700;
  line-height: 28px;
}

.unitPrice {
  font-size: 14px;
  line-height: 20px;
  color: var(--gray700);
}

.tagBox {
  padding-bottom: 12px;

  h3 {
    padding: 16px 20px 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: var(--gray900);
  }
}

.tags {
  overflow: auto;
  white-space: nowrap;
}

.tag {
  display: inline-block;
  border-radius: 18px;
  padding: 7px 14px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  margin-left: 4px;
  color: var(--orange600);
  border: 1px solid var(--orange100);
  background-color: white;

  &.selectedTag {
    color: var(--orange900);
    background-color: var(--orange100);
  }

  &:first-child {
    margin-left: 16px;
  }

  &:last-child {
    margin-right: 16px;
  }

  b {
    font-weight: 400;
    padding-right: 2px;
    color: var(--orange300);
  }
}

.outOfStock {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--backdrop60);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  font-size: 24px;
  font-weight: 700;
}
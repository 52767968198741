.labelItem {
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  gap: 6px;
  font-weight: 600;

  label {
    color: var(--gray600);
    font-size: 14px;
    font-weight: 500;
  }

  .text {
    color: var(--gray900);
    font-size: 18px;
  }

  .description {
    color: var(--gray900);
    font-size: 14px;
  }
}
.orderItem {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
  border-top: 1px solid var(--gray100);
}

.productName {
  font-family: 'Pretendard';
  font-weight: 600;
  font-size: 15px;
  color: var(--gray800);
}

.productPrice {
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  span:first-child {
    color: var(--gray600);
  }

  span:last-child {
    font-weight: 500;
  }

  &.outOfStuck {
    :first-child {
      text-decoration: line-through;
    }

    color: var(--red500);
  }
}

.changeLog {
  color: var(--orange700);
}
.findPasswordContainer {
  position: relative;
  padding: 0 28px;
  padding-bottom: var(--page-bottom-padding);
  background-color: white;
}

.submitSigninButton {
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  height: 52px; 
  margin-top: 20px;
}

.loadingBox {
  position: fixed;
  font-size: 42px;
  color: var(--orange500);
  top: calc(50% - 60px);
  left: calc(50% - 21px);
}
